import React from 'react';
import { NextSeo } from 'next-seo';
import { Button } from '@/components/ui/button';
import { Link } from '@/components/ui/link';

import style from '@/styles/404.module.css';
import clsx from 'clsx';

export default function NotFoundPage() {
  return (
    <>
      <NextSeo title="Not Found" />
      <div className="flex flex-1 bg-gray-50 justify-center items-center min-h-screen flex-col">
        <h2 className={clsx(style['error-text'])}>404</h2>
        <Link href="/" prefetch={false}>
          <Button className="rounded-md font-semibold">Go Back</Button>
        </Link>
      </div>
    </>
  );
}
